import "./styles.css";

import PartySocket from "partysocket";
import { createMessage, parseUpdate } from "./types";

declare const PARTYKIT_HOST: string;

/**
 * Create a new room for each page
 */
const getRoomId = () => {
  let room = window.location.pathname;
  if (room.startsWith("/")) room = room.slice(1);
  if (room.endsWith("/")) room = room.slice(0, -1);

  return room.replaceAll("/", "-") || "default";
};

/**
 * A PartySocket is like a WebSocket, except it's a bit more magical.
 * It handles reconnection logic, buffering messages while it's offline, and more.
 */
const room = getRoomId();
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: room,
});

const buttons = [...document.querySelectorAll(".reaction")].map((button) => {
  const kind = button.getAttribute("data-kind")!;
  button.addEventListener("click", async () => {
    conn.send(createMessage(kind));
  });

  return {
    kind: kind,
    count: parseInt(button.getAttribute("data-count") ?? "0", 10),
    element: button,
  };
});

let reactions: Record<string, number> = {};
let connections: number = 0;
let winner: Record<string, number> = {};
let win = "Who is the winner?";
const winnerElement = document.querySelector(".win");
const connectionElement = document.querySelector(".connections");

let emoji: Record<string, string> = {
  clap: "👏",
  heart: "❤️",
  party: "🎉",
};

// You can even start sending messages before the connection is open!
conn.addEventListener("message", (event) => {
  const update = parseUpdate(event.data);
  reactions = { ...reactions, ...update.reactions };
  win = update.win;

  for (const button of buttons) {
    if (reactions[button.kind]) {
      button.element.setAttribute(
        "data-count",
        reactions[button.kind].toString()
      );
    }
  }

  winnerElement?.setAttribute("data-something", emoji[win]);
});
